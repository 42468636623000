import { Component } from 'react';
import { connect } from 'react-redux';

const MIN_WIDTH = 420;

class Intercom extends Component {
  render() {
    const { isCookieBarOpen } = this.props;

    if (typeof window === 'undefined') {
      return null;
    }

    const width = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;

    if (
      (isCookieBarOpen || isCookieBarOpen === null) &&
      width < MIN_WIDTH
    ) {
      return null;
    }

    // intercom vendor code
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      var d = document;
      var i = function() {
        i.c(arguments);
      };
      i.q = [];
      i.c = function(args) {
          i.q.push(args);
      };
      w.Intercom = i;
      var l = function() {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/t50uguaj';
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };

      l();
    }

    return null;
  }
}

const mapStateToProps = ({ root }) => ({
  isCookieBarOpen: root.isCookieBarOpen,
})

export default connect(mapStateToProps)(Intercom);
