import React from 'react';
import Link from 'components/Link';
import styles from './errorBoundary.module.css';
import pageStyles from 'pages/pages.module.css';
import errorPageImage from 'utils/images/error_page.svg'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch() {
    this.setState({ hasError: true })
  }

  handleReloadPage = () => {
    window.location.reload();
  }

  resetErrorState = () => {
    this.setState({ hasError: false })
  }

  renderErrorPage = () => {
    return (
      <section className={[pageStyles.content, pageStyles.top, pageStyles.notFound].join(' ')}>
        <div className={[pageStyles.wrapper, pageStyles.wrapperNotFound].join(' ')}>
          <div className={styles.intro}>
            <div className={styles.msg}>
              <h1 className={styles.accent}>Sometning is technically wrong</h1>
              <h4>Thanks for noticing! We are already fixing this page - everything will get back to normal soon.</h4>
            </div>
            <div className={styles.buttonsContainer}>
              <button onClick={() => this.handleReloadPage()} className={styles.button}
              >
                Reload
              </button>
              <Link
                to="/"
                onClick={this.resetErrorState}
                className={[styles.button, styles.backToMain].join(' ')}
              >
                Back to Main
              </Link>
            </div>
          </div>
          <div className={styles.imageContainer}>
            <img src={errorPageImage} alt="error" className={styles.image} />
          </div>
        </div>
      </section>
    )
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          {this.renderErrorPage()}
        </>
      )
    }

    return this.props.children;
  }
}

export default ErrorBoundary;