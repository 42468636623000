import React, { Component } from 'react'
import cx from 'classnames';
import Link from 'components/Link';
import HeaderMenu from 'components/HeaderMenu';
import MobileMenu from 'components/MobileMenu';
import HeaderSections from 'components/HeaderSections';
import WL from 'constants/whiteLabel';
import styles from './header.module.css'

class Header extends Component {
  state = {
    isOpenMenu: null,
  };

  triggeMenu = e => {
    e.preventDefault();
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  }

  render() {
    const { extraTitle, withWrapper = true } = this.props;
    return (
      <header
        className={cx(
          styles.header,
          { [styles.background]: false }
        )}
      >
        <div className={styles.sectionsContainer}>
          <HeaderSections />
        </div>
        {
          withWrapper ? (
            <div className={styles.wrapper}>
              <div className={styles.root}>
                <div className={styles.rootBlock}>
                  <Link withLang to="/" className={styles.logoHead}>
                    <img
                      className={cx(
                        { [styles.noHeaderLogo]: !WL.isHeaderShown() }
                      )}
                      src={WL.getLogo()}
                      alt="logo"
                      width={WL.getLogoWidth()}
                      height={WL.getLogoHeight()}
                    />
                    {(WL.isHeaderShown() && extraTitle) ? (<span className={styles.extraTitle}>{extraTitle}</span>) : null}
                  </Link>
                </div>
    
                <button
                  type="button"
                  className={styles.mobBtnToggle}
                  onClick={this.triggeMenu}
                >
                  <span>Menu</span>
                </button>
    
                <div className={styles.menu}><HeaderMenu /></div>
                <div className={styles.mobileMenu} >
                  <MobileMenu isOpen={this.state.isOpenMenu} />
                </div>
              </div>
            </div>
          ) : null
        }
      </header>
    );
  }
}


export default Header
