import React, { Component } from 'react'
import onClickOutside from 'react-onclickoutside';
import _map from 'lodash/map';
import _pick from 'lodash/pick';
import _omit from 'lodash/omit';
import _trimEnd from 'lodash/trimEnd';
import _reduce from 'lodash/reduce';
import _concat from 'lodash/concat';
import _includes from 'lodash/includes';
import _get from 'lodash/get';
import enFlag from 'utils/images/EN_FLAG.svg'
import ruFlag from 'utils/images/RU_FLAG.svg'
import zhFlag from 'utils/images/CN_FLAG.svg'
import arrowDown from 'utils/images/arrow-down.svg'
import cx from 'classnames'
import WL from 'constants/whiteLabel'
import styles from './languageSwitcher.module.css'
import languages from 'constants/languages';
import { navigate } from 'gatsby';
import withLocation from 'helpers/withLocation';
import { injectIntl } from 'react-intl';

const ROOT_LANG = languages.EN;

const allLanguages = {
  [languages.EN]: {
    value: languages.EN,
    icon: enFlag
  },
  [languages.RU]: {
    value: languages.RU,
    icon: ruFlag
  },
  [languages.ZH]: {
    value: languages.ZH,
    icon: zhFlag
  }
};

const availableLanguages = _pick(allLanguages, WL.getLangs());

class LanguageSwitcher extends Component {
  state = {
    isLanguagesOpen: false,
  }

  language = this.props.intl.locale;

  toggleLanguages = () => {
    this.setState(state => ({ isLanguagesOpen: !state.isLanguagesOpen }))
  }

  handleClickOutside = () => {
    const { isLanguagesOpen } = this.state;

    if (!isLanguagesOpen) return;

    this.setState(state => ({ isLanguagesOpen: !state.isLanguagesOpen }));
  };

  preparePathForLangWithHash = (lang, path) => {
    const isSlashBeforeHash = path.split('#')[0].slice(-1) === '/';
    const apend = lang === ROOT_LANG ? '' : `_${lang}`;

    if (isSlashBeforeHash) {
      const preparedPathStart = path.split('#')[0].slice(0, -1);
      const preparedPathEnd = path.split('#')[1];

      return `${preparedPathStart}${apend}/#${preparedPathEnd}`
    } else {
      const preparedPathStart = path.split('#')[0];
      const preparedPathEnd = path.split('#')[1];

      return `${preparedPathStart}${apend}/#${preparedPathEnd}`
    }
  }

  removeLangFromPathWithHash = (oldLang, path) => {
    const isSlashBeforeHash = path.split('#')[0].slice(-1) === "/";
    if (isSlashBeforeHash) {
      const preparedPathStart = path.split('#')[0].slice(0, -1).replace(`_${oldLang}`, "");
      const preparedPathEnd = path.split('#')[1];

      return `${preparedPathStart}/#${preparedPathEnd}`
    } else {
      const preparedPathStart = path.split('#')[0].replace(`_${oldLang}`, "");
      const preparedPathEnd = path.split('#')[1];

      return `${preparedPathStart}#${preparedPathEnd}`
    }
  }

  preparePathWithHash = (oldLang, newLang, path) => {
    const res = this.removeLangFromPathWithHash(oldLang, path);

    return this.preparePathForLangWithHash(newLang, res);
  }

  isRoot = pathname => {
    if (pathname === '/' || pathname === '') {
      return true;
    }

    const values = Object.values(_omit(languages, [ROOT_LANG]));

    const variants = _reduce(
      values,
      (res, val) => _concat(res, [`/${val}`, `/${val}/`]),
      []
    );

    if (_includes(variants, pathname)) {
      return true;
    }

    return false;
  }

  handleLanguageChange = language => {
    const { location } = this.props;

    // do nothing if incoming language equal state language
    if (language === this.language) return;

    if (location.hash) {
      navigate(
        this.preparePathWithHash(
          this.language, language, `${location.pathname}${location.hash}`
        )
      );

      this.setState({ isLanguagesOpen: false });
      this.language = language;
      return;
    }

    if (this.isRoot(location.pathname)) {
      const newLang = language === ROOT_LANG ? '' : language;
      !newLang ? navigate(`/${newLang}`) : navigate(`/${newLang}/`);
      this.setState({ language });
      return;
    }

    let preparedPath = _trimEnd(location.pathname, '/');

    if (
      preparedPath.endsWith(`_${this.language}`)
    ) {
      preparedPath = preparedPath.slice(0, `_${this.language}`.length * -1)
    }

    if (language !== ROOT_LANG) {
      preparedPath = `${preparedPath}_${language}/`;
    } else {
      preparedPath = `${preparedPath}/`;
    }
    
    if(location.pathname.match('changelog')) {
      preparedPath = `${preparedPath}${location.search}`;
    }

    this.setState({ isLanguagesOpen: false });
    this.language = language;
    navigate(preparedPath);
  };

  render() {
    const { isLanguagesOpen } = this.state;
    const { intl } = this.props;
    
    const selectedLanguage = _get(allLanguages, intl.locale);

    return (
      <div className={cx({ [styles.noSmall]: !WL.isHeaderShown() })}>
        <div className={styles.currentLanguage} onClick={this.toggleLanguages} role="presentation">
          { WL.showLangFlags ? (<img src={selectedLanguage.icon} alt="language" className={styles.languageFlag}></img>) : null }
          <span className={styles.languageCaption}>{selectedLanguage.value.toUpperCase()}</span>
          <img src={arrowDown} alt="expand languages" className={styles.expandLanguages}></img>
        </div>

        {isLanguagesOpen && (
          <div className={styles.languagesContainer}>
            <ul className={styles.languages}>
              {_map(availableLanguages, lang => (
                <li
                  key={lang.value}
                  className={styles.languageItem}
                  onClick={() => this.handleLanguageChange(lang.value)}
                  role="presentation"
                >
                  { WL.showLangFlags ? (<img src={lang.icon} alt="language" className={styles.languageFlag} />) : null }
                  <span className={styles.languageCaption}>{lang.value.toUpperCase()}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    )
  }
}

const withClickOutside = onClickOutside(LanguageSwitcher);

export default injectIntl(withLocation(withClickOutside));
