import { metaMessages } from './metaMessages';

const meta = (lang = 'en') => ({
  default: {
    url: "/",
    title: metaMessages.defaultTitle,
    meta: [
      {
        name: "description",
        content: metaMessages.defaultMetaDescription,
      },
      { name: "keywords", content: "" },
      { name: "application-name", content: "Hive OS" },
      { name: "theme-color", content: "#1f2228" },
      { property: "og:title", content: metaMessages.defaultMetaOgTitle },
      {
        property: "twitter:image",
        content: "https://lbd.hiveos.farm/hiveon-site/static/main.png",
      },
    ],
  },
  install: {
    url: "/install",
    title: metaMessages.installTitle,
    meta: [
      {
        name: "description",
        content: metaMessages.installMetaDescription,
      },
      { name: "keywords", content: "" },
      { name: "application-name", content: "Hive OS" },
      { name: "theme-color", content: "#1f2228" },
      { property: "og:title", content: metaMessages.installMetaOgTitle },
      {
        property: "twitter:image",
        content: "https://lbd.hiveos.farm/hiveon-site/static/install.png",
      },
    ],
  },
  features: {
    url: "/features",
    title: metaMessages.featuresTitle,
    meta: [
      {
        name: "description",
        content: metaMessages.featuresMetaDescription,
      },
      { name: "keywords", content: "" },
      { name: "application-name", content: "Hive OS" },
      { name: "theme-color", content: "#1f2228" },
      { property: "og:title", content: metaMessages.featuresMetaOgTitle },
      {
        property: "twitter:image",
        content: "https://lbd.hiveos.farm/hiveon-site/static/features.png",
      },
    ],
  },
  pricing: {
    url: "/pricing",
    title: metaMessages.pricingTitle,
    meta: [
      {
        name: "description",
        content: metaMessages.pricingMetaDescription,
      },
      { name: "keywords", content: "" },
      { name: "application-name", content: "Hive OS" },
      { name: "theme-color", content: "#1f2228" },
      { property: "og:title", content: metaMessages.pricingMetaOgTitle },
      {
        property: "twitter:image",
        content: "https://lbd.hiveos.farm/hiveon-site/static/pricing.png",
      },
    ],
  },
  contact: {
    url: "/contact",
    title: metaMessages.contactTitle,
    meta: [
      {
        name: "description",
        content: metaMessages.contactMetaDescription,
      },
      { name: "keywords", content: "" },
      { name: "application-name", content: "Hive OS" },
      { name: "theme-color", content: "#1f2228" },
      { property: "og:title", content: metaMessages.contactMetaOgTitle },
      {
        property: "twitter:image",
        content: "https://lbd.hiveos.farm/hiveon-site/static/contacts.png",
      },
    ],
  },
  changelog: {
    url: "/changelog",
    title: metaMessages.changelogTitle,
    meta: [
      { name: "description", content: metaMessages.changelogMetaDescription },
      { name: "keywords", content: "" },
      { name: "application-name", content: "Hive OS" },
      { name: "msapplication-TileColor", content: "#1f2228" },
      { name: "theme-color", content: "#1f2228" },
      { property: "og:title", content: metaMessages.changelogMetaOgTitle },
      {
        property: "twitter:image",
        content: "https://lbd.hiveos.farm/hiveon-site/static/changelog.png",
      },
    ],
  },
  asic: {
    url: "/asic",
    title: metaMessages.asicTitle,
    meta: [
      {
        name: "description",
        content: metaMessages.asicMetaDescription,
      },
      { name: "keywords", content: "" },
      { name: "application-name", content: "Hive OS" },
      { name: "msapplication-TileColor", content: "#1f2228" },
      { name: "theme-color", content: "#1f2228" },
      {
        property: "og:title",
        content: metaMessages.asicMetaOgTitle,
      },
      {
        property: "twitter:image",
        content: "https://lbd.hiveos.farm/hiveon-site/static/asic.png",
      },
    ],
  },
  statistics: {
    url: "/statistics",
    title: metaMessages.statisticsTitle,
    meta: [
      {
        name: "description",
        content: metaMessages.statisticsMetaDescription,
      },
      { name: "keywords", content: "" },
      { name: "application-name", content: "Hive OS" },
      { name: "msapplication-TileColor", content: "#1f2228" },
      { name: "theme-color", content: "#1f2228" },
      { property: "og:title", content: metaMessages.statisticsMetaOgTitle },
      {
        property: "twitter:image",
        content: "https://lbd.hiveos.farm/hiveon-site/static/statistics.png",
      },
    ],
  },
  l3: {
    url: "/asic/l3",
    title: metaMessages.l3Title,
    meta: [
      {
        name: "description",
        content: metaMessages.l3MetaDescription,
      },
      { name: "keywords", content: "" },
      { name: "application-name", content: "Hive OS" },
      { name: "msapplication-TileColor", content: "#1f2228" },
      { name: "theme-color", content: "#1f2228" },
      { property: "og:title", content: metaMessages.l3MetaOgTitle },
      {
        property: "twitter:image",
        content: "https://lbd.hiveos.farm/hiveon-site/static/L3.png",
      },
    ],
  },
  s9: {
    url: "/asic/s9",
    title: metaMessages.s9Title,
    meta: [
      {
        name: "description",
        content: metaMessages.s9MetaDescription,
      },
      { name: "keywords", content: "" },
      { name: "application-name", content: "Hive OS" },
      { name: "msapplication-TileColor", content: "#1f2228" },
      { name: "theme-color", content: "#1f2228" },
      { property: "og:title", content: metaMessages.s9MetaOgTitle },
      {
        property: "twitter:image",
        content: "https://lbd.hiveos.farm/hiveon-site/static/S9.png",
      },
    ],
  },
  s10: {
    url: "/asic/s10",
    title: metaMessages.s10Title,
    meta: [
      {
        name: "description",
        content: metaMessages.s10MetaDescription,
      },
      { name: "keywords", content: "" },
      { name: "application-name", content: "Hive OS" },
      { name: "msapplication-TileColor", content: "#1f2228" },
      { name: "theme-color", content: "#1f2228" },
      { property: "og:title", content: metaMessages.s10MetaOgTitle },
      {
        property: "twitter:image",
        content: "https://lbd.hiveos.farm/hiveon-site/static/S10.png",
      },
    ],
  },
  s17: {
    url: "/asic/s17",
    title: metaMessages.s17Title,
    meta: [
      {
        name: "description",
        content: metaMessages.s17MetaDescription,
      },
      { name: "keywords", content: "" },
      { name: "application-name", content: "Hive OS" },
      { name: "msapplication-TileColor", content: "#1f2228" },
      { name: "theme-color", content: "#1f2228" },
      {
        property: "og:title",
        content: metaMessages.s17MetaOgTitle,
      },
      {
        property: "twitter:image",
        content: "https://lbd.hiveos.farm/hiveon-site/static/S17.png",
      },
    ],
  },
  t9: {
    url: "/asic/t9",
    title: metaMessages.t9Title,
    meta: [
      {
        name: "description",
        content: metaMessages.t9MetaDescription,
      },
      { name: "keywords", content: "" },
      { name: "application-name", content: "Hive OS" },
      { name: "msapplication-TileColor", content: "#1f2228" },
      { name: "theme-color", content: "#1f2228" },
      { property: "og:title", content: metaMessages.t9MetaOgTitle },
      {
        property: "twitter:image",
        content: "https://lbd.hiveos.farm/hiveon-site/static/T9.png",
      },
    ],
  },
  t17: {
    url: "/asic/t17",
    title: metaMessages.t17Title,
    meta: [
      {
        name: "description",
        content: metaMessages.t17MetaDescription,
      },
      { name: "keywords", content: "" },
      { name: "application-name", content: "Hive OS" },
      { name: "msapplication-TileColor", content: "#1f2228" },
      { name: "theme-color", content: "#1f2228" },
      { property: "og:title", content: metaMessages.t17MetaOgTitle },
      {
        property: "twitter:image",
        content: "https://lbd.hiveos.farm/hiveon-site/static/T17.png",
      },
    ],
  },
  privacy: {
    url: "/privacy",
    title: metaMessages.privacyTitle,
    meta: [
      {
        name: "description",
        content: metaMessages.privacyMetaDescription,
      },
      { name: "keywords", content: "" },
      { name: "application-name", content: "Hive OS" },
      { name: "msapplication-TileColor", content: "#1f2228" },
      { name: "theme-color", content: "#1f2228" },
      { property: "og:title", content: metaMessages.privacyMetaOgTitle },
      {
        property: "twitter:image",
        content: "https://lbd.hiveos.farm/hiveon-site/static/policy.png",
      },
    ],
  },
  terms: {
    url: "/terms",
    title: metaMessages.termsTitle,
    meta: [
      {
        name: "description",
        content: metaMessages.termsMetaDescription,
      },
      { name: "keywords", content: "" },
      { name: "application-name", content: "Hive OS" },
      { name: "msapplication-TileColor", content: "#1f2228" },
      { name: "theme-color", content: "#1f2228" },
      { property: "og:title", content: metaMessages.termsMetaOgTitle },
      {
        property: "twitter:image",
        content: "https://lbd.hiveos.farm/hiveon-site/static/terms.png",
      },
    ],
  },
  "knowledge-base": {
    url: "/knowledge-base",
    title: metaMessages.knowledgebaseTitle,
    meta: [
      {
        name: "description",
        content: metaMessages.knowledgebaseMetaDescription,
      },
      { name: "keywords", content: "" },
      { name: "application-name", content: "Hive OS" },
      { name: "msapplication-TileColor", content: "#1f2228" },
      { name: "theme-color", content: "#1f2228" },
      { property: "og:title", content: metaMessages.knowledgebaseMetaOgTitle },
      {
        property: "twitter:image",
        content: "https://lbd.hiveos.farm/hiveon-site/static/kb.png",
      },
    ],
  },
  blog: {
    url: "/blog",
    title: metaMessages.blogTitle,
    meta: [
      {
        name: "description",
        content: metaMessages.blogMetaDescription,
      },
      { name: "keywords", content: "" },
      { name: "application-name", content: "Hive OS" },
      { name: "msapplication-TileColor", content: "#1f2228" },
      { name: "theme-color", content: "#1f2228" },
      { property: "og:title", content: metaMessages.blogMetaOgTitle },
      {
        property: "twitter:image",
        content: "https://lbd.hiveos.farm/hiveon-site/static/main.png",
      },
    ],
  },
  bugbounty: {
    url: "/bugbounty",
    title: metaMessages.bugbountyTitle,
    meta: [
      {
        name: "description",
        content: metaMessages.bugbountyMetaDescription,
      },
      { name: "keywords", content: "" },
      { name: "application-name", content: "Hive OS" },
      { name: "msapplication-TileColor", content: "#1f2228" },
      { name: "theme-color", content: "#1f2228" },
      { property: "og:title", content: metaMessages.bugbountyMetaOgTitle },
      {
        property: "twitter:image",
        content: "https://lbd.hiveos.farm/hiveon-site/static/main.png",
      },
    ],
  },
  ASICHub: {
    url: "/asichub",
    title: metaMessages.ASICHub,
    meta: [
      {
        name: "description",
        content: metaMessages.asichubMetaDescription,
      },
      { name: "keywords", content: "" },
      { name: "application-name", content: "Hive OS" },
      { name: "theme-color", content: "#1f2228" },
      { property: 'og:image', content: `https://lbd.hiveos.farm/hiveon-site/static/meta_asichub_${lang}.png` },
      { property: "og:title", content: metaMessages.defaultMetaOgTitle },
      {
        property: "twitter:image",
        content: `https://lbd.hiveos.farm/hiveon-site/static/meta_asichub_${lang}.png`,
      },
    ],
  },
  referral: {
    url: "/referral",
    title: metaMessages.referralTitle,
    meta: [
      {
        name: "description",
        content: metaMessages.referralDescription,
      },
      { name: "keywords", content: "" },
      { name: "application-name", content: "Hive OS" },
      { name: "theme-color", content: "#1f2228" },
      { property: "og:title", content: metaMessages.defaultMetaOgTitle },
      {
        property: "twitter:image",
        content: "https://lbd.hiveos.farm/hiveon-site/static/main.png",
      },
    ],
  },
  // default: {
  //   url: "/ru",
  //   title: "Универсальная майнинг-платформа",
  //   meta: [
  //     {
  //       name: "description",
  //       content:
  //         "Get more from your GPUs and ASICs: batch management, schedules for automatic management, autofan, statistics, and much more",
  //     },
  //     { name: "keywords", content: "" },
  //     { name: "application-name", content: "Hive OS" },
  //     { name: "theme-color", content: "#1f2228" },
  //     { property: "og:title", content: "The Ultimate Mining Platform" },
  //     {
  //       property: "twitter:image",
  //       content: "https://lbd.hiveos.farm/hiveon-site/static/main.png",
  //     },
  //   ],
  // },
  // privacy: {
  //   url: "/privacy-ru",
  //   title: "Политика Конфиденциальности Hiveon",
  //   meta: [
  //     {
  //       name: "description",
  //       content:
  //         "Узнайте, как именно собираются и используются данные в Hive OS",
  //     },
  //     { name: "keywords", content: "" },
  //     { name: "application-name", content: "Hive OS" },
  //     { name: "msapplication-TileColor", content: "#1f2228" },
  //     { name: "theme-color", content: "#1f2228" },
  //     { property: "og:title", content: "Политика Конфиденциальности Hiveon" },
  //     {
  //       property: "twitter:image",
  //       content: "https://lbd.hiveos.farm/hiveon-site/static/policy_ru.png",
  //     },
  //   ],
  // },
  // terms: {
  //   url: "/terms-ru",
  //   title: "Пользовательское Соглашение Hiveon",
  //   meta: [
  //     {
  //       name: "description",
  //       content: "Узнайте всё о правилах использования услуг Hive OS",
  //     },
  //     { name: "keywords", content: "" },
  //     { name: "application-name", content: "Hive OS" },
  //     { name: "msapplication-TileColor", content: "#1f2228" },
  //     { name: "theme-color", content: "#1f2228" },
  //     { property: "og:title", content: "Пользовательское Соглашение Hiveon" },
  //     {
  //       property: "twitter:image",
  //       content: "https://lbd.hiveos.farm/hiveon-site/static/terms_ru.png",
  //     },
  //   ],
  // },
  // "knowledge-base": {
  //   url: "/knowledge-base_ru",
  //   title: "База Знаний",
  //   meta: [
  //     {
  //       name: "description",
  //       content:
  //         "Руководства, мануалы и статьи о Hive OS, Hiveon ASIC Firmware и Hiveon Pool: найдите ответы на все ваши вопросы",
  //     },
  //     { name: "keywords", content: "" },
  //     { name: "application-name", content: "Hive OS" },
  //     { name: "theme-color", content: "#1f2228" },
  //     { name: "msapplication-TileColor", content: "#1f2228" },
  //     { property: "og:title", content: "База Знаний" },
  //     {
  //       property: "twitter:image",
  //       content: "https://lbd.hiveos.farm/hiveon-site/static/kb_ru.png",
  //     },
  //   ],
  // },
});

export default meta;
