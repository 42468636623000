import React, { Component } from 'react';
import cx from 'classnames';
import HeaderMenu from 'components/HeaderMenu';
import Social from 'components/social/social';
import Footer from 'components/footer/footer';
import LanguageSwitcher from 'components/LanguageSwitcher';
import WL from 'constants/whiteLabel';
import styles from './mobileMenu.module.css';

class MobileMenu extends Component {
  componentDidUpdate () {
    const { isOpen } = this.props;
    const style = document.body.style;

    if (isOpen) {
      style.position = 'fixed';
      style.width = '100vw';
    } else {
      style.position = 'initial';
      style.width = 'auto';
    }
  }

  componentWillUnmount () {
    const style = document.body.style;

    style.position = 'initial';
    style.width = 'auto';
  }

  render () {
    const { isOpen } = this.props;

    return (
      <div className={
          cx(styles.root,
            {
              [styles.slideNone]: (isOpen === null),
              [styles.slideIn]: (!isOpen && isOpen !== null),
              [styles.slideOut]: (isOpen && isOpen !== null)
            }
          )
        }>
        <div className={styles.container}>
          {!WL.isHeaderShown() && (
            <div className={styles.languageSwitcher}>
              <LanguageSwitcher />
            </div>
          )}
          <HeaderMenu />
          <Social />
          <Footer />
        </div>
      </div>
    );
  }
}

export default MobileMenu;
