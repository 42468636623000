import WL from 'constants/whiteLabel';

const { key: wlKey } = WL;

const hiveosFavicons = {
    appleTouchIcon: '/apple-touch-icon.png',
    favicon32: '/favicon-32x32.png',
    favicon16: '/favicons/favicon-16x16.png',
    safariPinnedTab: '/favicons/safari-pinned-tab.svg',
    favicon: '/favicon.ico',
};
const bigminerFavicons = {
    appleTouchIcon: '/bigminer/apple-touch-icon.png',
    favicon32: '/bigminer/favicon-32x32.png',
    favicon16: '/bigminer/favicons/favicon-16x16.png',
    safariPinnedTab: '/bigminer/favicons/safari-pinned-tab.svg',
    favicon: '/bigminer/favicon.ico',
};

const iconsMap = {
    'hiveos': hiveosFavicons,
    'bigminer': bigminerFavicons,
}

export default iconsMap[wlKey] || hiveosFavicons;
