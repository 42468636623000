import React from 'react';
import styles from './headerMenu.module.css';
import { FormattedMessage } from 'react-intl';
import Link from 'components/Link';

import WL from 'constants/whiteLabel';

const Menu = () => (
  <div className={styles.root}>
    {
      WL.hasMenuHome ? (
        <div className={styles.primary}>
          <Link
            withLang
            to="/"
            activeClassName={styles.active}
            className={styles.rootLink}
          >
            <FormattedMessage id="HeaderMenu.home" defaultMessage="Home page" />
              </Link>
        </div>
      ) : null
    }
    <div className={styles.primary}>
      <Link
        withLang
        to="/features"
        activeClassName={styles.active}
        className={styles.rootLink}
      >
        <FormattedMessage id="HeaderMenu.features" defaultMessage="Features" />
          </Link>
    </div>
    <div className={styles.primary}>
      <Link
        withLang
        to="/pricing"
        activeClassName={styles.active}
        className={styles.rootLink}
      >
        <FormattedMessage id="HeaderMenu.pricing" defaultMessage="Pricing" />
          </Link>
    </div>
    <div className={styles.primary}>
      <Link
        withLang
        to="/install"
        activeClassName={styles.active}
        className={styles.rootLink}
      >
        <FormattedMessage id="HeaderMenu.install" defaultMessage="Install" />
          </Link>
    </div>
    <div className={styles.primary}>
      <Link
        external
        noRef
        href={WL.getLoginLink()}
        className={[styles.rootLink, styles.signIn].join(' ')}
        target={WL.regLinkTarget}
      >
        <FormattedMessage id="HeaderMenu.signin" defaultMessage="Sign In" />
      </Link>
    </div>
  </div>
)

export default Menu;
