const PATHES_REDIRECTING = [
  {
    from: ['/asic/l3/Asic/', '/asic/l3/Asic'],
    to: '/asic/l3/'
  },
  {
    from: ['/asic/s10/Asic/', '/asic/s10/Asic'],
    to: '/asic/s10/'
  },
  {
    from: ['/asic/s17/Asic/', '/asic/s17/Asic'],
    to: '/asic/s17/'
  },
  {
    from: ['/asic/s9/Asic/', '/asic/s9/Asic'],
    to: '/asic/s9/'
  },
  {
    from: ['/asic/t17/Asic/', '/asic/t17/Asic'],
    to: '/asic/t17/'
  },
  {
    from: ['/asic/t9/Asic/', '/asic/t9/Asic'],
    to: '/asic/t9/'
  },
  {
    from: ['/asicvvs/Asicvvs/', '/asicvvs/Asicvvs'],
    to: '/asicvvs/'
  },
  {
    from: ['/asic/Asic/', '/asic/Asic/'],
    to: '/asic/'
  },
  {
    from: ['/changelog/changelog/', '/changelog/changelog'],
    to: '/changelog/'
  },
  {
    from: ['/contact/contact/', '/contact/contact'],
    to: '/contact/'
  },
  {
    from: ['/features/features/', '/features/features'],
    to: '/features/'
  },
  {
    from: ['/404/404/', '/404/404'],
    to: '/404/'
  },
  {
    from: ['/install/install/', '/install/install'],
    to: '/install/'
  },
  {
    from: ['/pricing/pricing/', '/pricing/pricing'],
    to: '/pricing/'
  },
  {
    from: ['/privacy/Privacy/', '/privacy/Privacy'],
    to: '/privacy/'
  },
  {
    from: ['/statistics/Statistics/', '/statistics/Statistics'],
    to: '/statistics/'
  },
  {
    from: ['/terms/Terms/', '/terms/Terms'],
    to: '/terms/'
  },
];

module.exports = {
  PATHES_REDIRECTING,
};
