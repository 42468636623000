import React, { Fragment } from 'react';
import isSupported from 'utils/browser-support';
import tornado from 'utils/images/tornado.svg';

const NotSupportedBrowser = props => (
  <Fragment>
    {!isSupported() && (
      <center>
        <br /><br /><br />
        <img src={tornado} alt="tornado" />
        <h1>Sorry, your browser is not supported</h1>
      </center>
    )}
    {isSupported() && props.children}
  </Fragment>
);

export default NotSupportedBrowser;
