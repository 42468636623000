// import browser from 'browser-detect';
import { detect } from 'detect-browser';

const version = {
  all: [
    { name: 'chrome', versionNumber: 38 },
    { name: 'ie', versionNumber: 11 },
    { name: 'safari', versionNumber: 9 },
    { name: 'firefox', versionNumber: 32 },
    { name: 'samsung', versionNumber: 5 },
  ],
  // TO REMOVE: Old browser detection
  // desktop: [
  //   { name: 'chrome', versionNumber: 38 },
  //   { name: 'ie', versionNumber: 11 },
  //   { name: 'safari', versionNumber: 9 },
  //   { name: 'firefox', versionNumber: 32 },
  // ],
  // mobile: [
  //   { name: 'chrome', versionNumber: 38 },
  //   { name: 'safari', versionNumber: 9 },
  //   { name: 'firefox', versionNumber: 32 },
  //   { name: 'samsung', versionNumber: 5 }
  // ]
};

const isSupported = () => {
  const currentBrowser = detect();

  // TO REMOVE: Old browser detection
  // const result = browser();
  // const compare = result.mobile ? version.mobile : version.desktop;

  const compare = version.all;
  let isSupported = true;

  if (!currentBrowser) {
    isSupported = false;
  }

  compare.forEach(item => {
    if (
      item.name === currentBrowser.name &&
      item.versionNumber > parseFloat(currentBrowser.versionNumber)
    ) {
      isSupported = false;
    }
  });

  return isSupported;
}

export default isSupported
