import 'whatwg-fetch';
import 'utils/object.isInfinite.polyfill.js';
import 'es6-symbol/polyfill';

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TypographyStyle, GoogleFont } from 'react-typography';
import cx from 'classnames';
import NotSupportedBrowser from 'components/NotSupportedBrowser';
import Header from 'components/Header';
import ErrorBoundary from 'components/ErrorBoundary';
import favicons from 'utils/favicons';
import typography from 'utils/typography';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _startsWith from 'lodash/startsWith';
import _endsWith from 'lodash/endsWith';
import styles from './layout.module.css';
import meta from 'constants/meta.js';
import WL from 'constants/whiteLabel';
import Intercom from 'components/Intercom';
import Link from 'components/Link';
import { STATIC_SERVER } from 'constants/index';
import asicModels from 'constants/asicModels';
import { PATHES_REDIRECTING } from 'constants/redirects';
import withLocation from 'helpers/withLocation';
import './layout.css';

const ASIC_MAIN_PAGE = 'asic';
const ASIC_HUB_PAGE = 'ASICHub';
const REFERRAL_PAGE = 'referral';

const ASIC_PAGES = [
  ..._map(Object.values(asicModels), model => _get(model, 'key', '').toLowerCase()),
  ASIC_MAIN_PAGE,
]

const getPageMetaWithLocales = (meta, intlFormat) => {
  if (!intlFormat) return meta;

  const preparedPageMeta = {
    ...meta,
    title: intlFormat(meta.title),
    meta: meta.meta.map(item => {
      if (item.name === 'description' || item.property === 'og:description') {
        return { ...item, content: intlFormat(item.content) }
      }
      if (item.name === 'twitter:description' || item.property === 'twitter:description') {
        return { ...item, content: intlFormat(item.content) }
      }
      if (item.name === 'og:title' || item.property === 'og:title') {
        return { ...item, content: intlFormat(item.content) }
      }
      return item;
    })
  };

  return preparedPageMeta;
}

/* eslint-disable-next-line */
const getDesc = (page, customMeta, lang = 'en', intlFormat) => {
  const metaData = meta(lang)[page];

  if (
    (page !== null && (!metaData)) ||
    (page === null && !customMeta)
  ) {
    throw new Error(`No page meta. Page: ${page}.`);
  }

  let resMeta = customMeta;

  if (!customMeta && metaData) {
    resMeta = getPageMetaWithLocales(metaData, intlFormat);
  }

  const metaArr = _get(resMeta, 'meta', []);
  const appName = _find(metaArr, ({ name }) => name === 'application-name');
  const themeColor = _find(metaArr, ({ name }) => name === 'theme-color');
  const description = _find(metaArr, ({ name }) => name === 'description');

  if (
    appName &&
    !_find(metaArr, ({ name }) => name === 'apple-mobile-web-app-title')
  ) {
    resMeta.meta.push({
      name: 'apple-mobile-web-app-title',
      content: appName.content
    });
  }

  if (
    themeColor &&
    !_find(metaArr, ({ name }) => name === 'msapplication-TileColor')
  ) {
    resMeta.meta.push({
      name: 'msapplication-TileColor',
      content: themeColor.content,
    });
  }

  if (
    description &&
    !_find(metaArr, ({ property }) => property === 'og:site_name')
  ) {
    resMeta.meta.push({
      property: 'og:site_name',
      content: 'Hive OS',
    });
  }

  if (
    description &&
    !_find(metaArr, ({ property }) => property === 'og:description')
  ) {
    resMeta.meta.push({
      property: 'og:description',
      content: description.content,
    });
  }

  if (
    !_find(metaArr, ({ name }) => name === 'twitter:image') &&
    !_find(metaArr, ({ property }) => property === 'og:image')
  ) {
    resMeta.meta.push({
      property: 'og:image',
      content: `${STATIC_SERVER}/og-main.png`,
    });
  }

  if (!_find(metaArr, ({ name }) => name === 'twitter:card')) {
    resMeta.meta.push({
      name: 'twitter:card',
      content: 'summary_large_image',
    });
  }

  if (!_find(metaArr, ({ name }) => name === 'twitter:title')) {
    resMeta.meta.push({
      name: 'twitter:title',
      content: 'Hive OS',
    });
  }

  if (description &&
    !_find(metaArr, ({ name }) => name === 'twitter:description')) {
    resMeta.meta.push({
      name: 'twitter:description',
      content: description.content,
    });
  }

  if (!_find(metaArr, ({ name }) => name === 'twitter:site')) {
    resMeta.meta.push({
      name: 'twitter:site',
      content: '@hiveos',
    });
  }

  return resMeta;
};

const getTitle = (page, customMeta, lang = 'en', intlFormat) => {
  const desc = getDesc(page, customMeta, lang, intlFormat);
  let title = _get(desc, 'title', 'Hive OS – The Ultimate Mining Platform');

  if (title !== 'Hive OS – The Ultimate Mining Platform') {
    title += ' | ' + WL.getName();
  }

  return title;
};

const getMeta = (page, customMeta, lang = 'en', intlFormat) => {
  const desc = getDesc(page, customMeta, lang, intlFormat);

  return _get(desc, 'meta', []);
};

const getLinkCanonicalLangString = (language, pageName, page) => {
  // check if english, or pageName is a path with lang in the end, so should return ''
  if (language === 'en' || _endsWith(pageName, `_${language}/`)) {
    return '';
  }
  if (language !== 'en') {
    // check root page
    if (page === 'default' && !pageName) {
     return `${language}/`;
    }
    return `_${language}`
  }
}

const getLinkCanonical = (location, page, params) => {
  const origin = 'https://hiveos.farm';
  // add second pageName param, cause page prop used in meta info
  const pageName = _get(params, 'pageName');
  // set lang to prepare canonical link
  const lang = getLinkCanonicalLangString(_get(params, 'lang'), pageName, page);
  // check is page on hardcoded redirect path
  const foundedRedirect = _find(PATHES_REDIRECTING, ({ from = [] }) => from.includes(location.pathname));
  if (foundedRedirect) {
    return <link rel="canonical" href={`${origin}${foundedRedirect.to.slice(0, -1)}${lang}/`}/>
  }
  // check asic models pages
  const isAsicPage = _get(params, 'isAsicPage');
  if (isAsicPage) {
    return <link rel="canonical" href={`${origin}/asic${lang}/`}/>
  }

  if (pageName) {
    let name = pageName;
    if (_startsWith(name, '/')) {
      name = name.slice(1)
    }
    if (_endsWith(name, '/')) {
      name = name.slice(0, -1)
    }
    return <link rel="canonical" href={`${origin}/${name}${lang}/`}/>
  }

  if (page === 'default') {
    return <link rel="canonical" href={`${origin}/${lang}`}/>
  }

  if (page) {
    return <link rel="canonical" href={`${origin}/${page}${lang}/`}/>
  }

  return null;
}

const Layout = ({
  children,
  customMeta,
  withHeaderWrapper = true,
  withHeader = true,
  withIntercom = true,
  page = 'default',
  lang = 'en',
  intl,
  extraTitle,
  location,
  isAsicPage,
  pageName,
}) => {
  return (
    <NotSupportedBrowser>
      <StaticQuery
        query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
        render={() => (
          <>
            <Helmet
              title={getTitle(page, customMeta, lang, intl.formatMessage)}
              meta={getMeta(page, customMeta, lang, intl.formatMessage)}
              htmlAttributes={{
                lang
              }}
            >
              <TypographyStyle typography={typography}/>
              <GoogleFont typography={typography}/>

              <link rel="icon" href={favicons.favicon} type="image/x-icon"/>
              <link rel="apple-touch-icon" href={favicons.appleTouchIcon}/>
              <link rel="shortcut icon" href={favicons.appleTouchIcon}/>
              <link type="image/x-icon" rel="icon" href={favicons.favicon32}/>
              <link rel="safari-pinned-tab" href={favicons.safariPinnedTab}/>
              <link rel="mask-icon" href={favicons.safariPinnedTab}/>
              <link rel="icon" type="image/png" sizes="32x32" href={favicons.favicon32}/>
              <link rel="icon" type="image/png" sizes="16x16" href={favicons.favicon16}/>
              {WL.withLinkCanonical && getLinkCanonical(location, page, { isAsicPage, pageName, lang })}

            </Helmet>
            {withHeader && <Header withWrapper={withHeaderWrapper} siteTitle={getTitle(page, customMeta, lang)} extraTitle={extraTitle}/>}
            <div
              style={{
                margin: "0 auto",
                paddingTop: 0,
                overflow: "hidden"
              }}
            >
              <div className={cx(styles.intro, { 
                [styles.asicIntro]: ASIC_PAGES.includes(page),
                [styles.asicHubIntro]: page === ASIC_HUB_PAGE,
                [styles.referral]: page === REFERRAL_PAGE,
              })}></div>
              <ErrorBoundary>
                {children}
              </ErrorBoundary>
            </div>
          </>
        )}
      />
      { withIntercom ? (<Intercom />) : null }
      {/* {WL.footer && (
        <div className={styles.footer}>
          <Link to={WL.footer.href} external>
            <FormattedMessage
              id={WL.footer.text}
              defaultMessage="Beijing ICP No. 2021001233"
            />
          </Link>
        </div>
      )} */}
    </NotSupportedBrowser>
  );
};

Layout.propTypes = {
  page: PropTypes.string,
  extraTitle: PropTypes.string,
  children: PropTypes.node.isRequired,
  isAsicPage: PropTypes.bool,
  withHeaderWrapper: PropTypes.bool,
  pageName: PropTypes.string,
};

Layout.defaultProps = {
  page: 'default',
  isAsicPage: false,
  pageName: null,
}

export default injectIntl(withLocation(Layout))
