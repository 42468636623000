import React from 'react';
import { FormattedMessage } from 'react-intl';
import globalStyles from 'pages/pages.module.css';
import styles from './style.module.css';
import WL from 'constants/whiteLabel';
import Link from 'components/Link';

const Footer = () => (
    <div className={[globalStyles.wrapper, styles.footer].join(' ')}>
        <img
          src={WL.getSmallLogo()}
          width={WL.getSmallLogoWidth()}
          height={WL.getSmallLogoHeight()}
          alt="hiveon logo in footer"
          className={styles.img}
        />
        <div className={styles.row}>
            <Link withLang to="/install" className={styles.cell}>
                <FormattedMessage id="getting_started" defaultMessage="Getting started" />
            </Link>
            <Link withLang to="/features" className={styles.cell}>
                <FormattedMessage id="why_hove_os" defaultMessage="Why Hive OS" />
            </Link>
            <Link withLang to="/pricing" className={styles.cell}>
                <FormattedMessage id="pricing" defaultMessage="Pricing" />
            </Link>
            {WL.hasApi() && (
              <Link withLang to="/referral" className={styles.cell}>
                  <FormattedMessage id="referal_program" defaultMessage="Referal Program" />
              </Link>
            )}

            {WL.hasApi() ? null : (
              <Link withLang to="/contact" className={styles.cell}>
                  <FormattedMessage id="contact" defaultMessage="Contact" />
              </Link>
            )}
        </div>
        <div className={styles.row}>
            {WL.hasApi() && (
              <Link withLang to="/contact" className={styles.cell}>
                  <FormattedMessage id="contact" defaultMessage="Contact" />
              </Link>
            )}

            {WL.hasApi() && (
              <Link withLang to="/changelog" className={styles.cell}>
                  <FormattedMessage id="changelog" defaultMessage="Changelog" />
              </Link>
            )}

            {WL.hasApi() && (
              <Link external noRef href="https://forum.hiveos.farm/t/hive-api-v2/4490" className={styles.cell} target="_self">
                <FormattedMessage id="hive_os_api" defaultMessage="Hive OS API" />
              </Link>
            )}
            {WL.hasApi() && (
              <Link withLang to="/terms" className={styles.cell}>
                  <FormattedMessage id="terms_of_use" defaultMessage="Terms of Use" />
              </Link>
            )}

            {!!WL.getStatus() && (
              <Link external noRef href={WL.getStatus()} className={styles.cell} target="_self">
                <FormattedMessage id="status" defaultMessage="Status" />
              </Link>
            )}
        </div>
        <div className={styles.row}>
          {WL.hasStatistics() && (
            <Link withLang to="/statistics" className={styles.cell}>
              <FormattedMessage id="statistics" defaultMessage="Statistics" />
            </Link>
          )}
          {WL.hasKb() && (
            <Link withLang to="/knowledge-base" className={styles.cell}>
              <FormattedMessage id="knowledge_base" defaultMessage="Knowledge base" />
            </Link>
          )}
          {WL.hasIntercom() && (
            <Link withLang to="/bugbounty" className={styles.cell}>
              <FormattedMessage id="bugBounty" defaultMessage="Bug Bounty" />
            </Link>
          )}
        </div>
    </div>
)

export default Footer;
