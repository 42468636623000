import React from 'react';
import { FormattedMessage } from 'react-intl';
import Link from 'components/Link';
import globalStyles from 'pages/pages.module.css';
import styles from './style.module.css';
import WL from 'constants/whiteLabel';
import tg from 'utils/images/telegram.svg';
import yt from 'utils/images/youtube.svg';
import gh from 'utils/images/github_gold.svg';
import at from 'utils/images/email.svg';
import tw from 'utils/images/twitter.svg';
import fb from 'utils/images/facebook.svg';
import blog from 'utils/images/blog.svg';

const Social = props => (
    <div className={[globalStyles.wrapper, styles.social, (props.hideMobile ? styles.hideMobile : '' )].join(' ')}>
        <div className={styles.socialRow}>
          {!!WL.getTelegram() && (
            <Link href={WL.getTelegram()} className={styles.socialItem} external>
                <img src={tg} alt="telegram icon" width={36} height={36} className={styles.socialIcon}/>
                <div className={styles.socialTitle}>
                    <FormattedMessage id="announcements" defaultMessage="Announcements" />
                </div>
                <div className={styles.socialDesc}>
                    <FormattedMessage id="announcements_caption" defaultMessage="Get news, releases changelog and urgent announcements" />
                </div>
            </Link>
          )}

          {!!WL.getYoutube() && (
            <Link external href={WL.getYoutube()} className={styles.socialItem}>
                <img src={yt} width={36} height={36} alt="youtube icon" className={styles.socialIcon}/>
                <div className={styles.socialTitle}>
                    <FormattedMessage id="helpful_videos" defaultMessage="Helpful Videos" />
                </div>
                <div className={styles.socialDesc}>
                    <FormattedMessage id="helpful_videos_caption" defaultMessage="How to setup, config and use the system" />
                </div>
            </Link>
          )}

          {!!WL.getGithub() && (
            <Link external href={WL.getGithub()} className={styles.socialItem}>
                <img src={gh} width={36} height={36} alt="github icon" className={styles.socialIcon}/>
                <div className={styles.socialTitle}>Github</div>
                <div className={styles.socialDesc}>
                    <FormattedMessage id="github_caption" defaultMessage="Official Repository" />
                </div>
            </Link>
          )}
        </div>
        <div className={styles.socialRow}>
            <a href={`mailto:${WL.getSupportEmail()}`} className={styles.socialItem}>
                <img src={at} width={36} height={36} alt="email icon" className={styles.socialIcon}/>
                <div className={styles.socialTitle}>{WL.getSupportEmail()}</div>
                <div className={styles.socialDesc}>
                    <FormattedMessage id="support_caption" defaultMessage="Official Support" />
                </div>
            </a>

            {!!WL.getTwitter() && (
              <Link external href={WL.getTwitter()} className={styles.socialItem}>
                <img src={tw} width={36} height={36} alt="twitter icon" className={styles.socialIcon}/>
                <div className={styles.socialTitle}>Twi! Hive! Twi!</div>
                <div className={styles.socialDesc}>
                    <FormattedMessage id="twitter_caption" defaultMessage="Follow! We have cookies." />
                </div>
              </Link>
            )}

            {!!WL.getFacebook() && (
              <Link external href={WL.getFacebook()} className={styles.socialItem}>
                <img src={fb} width={36} height={36} alt="facebook icon" className={styles.socialIcon}/>
                <div className={styles.socialTitle}>
                    <FormattedMessage id="facebook_official_page" defaultMessage="Official Page" />
                </div>
                <div className={styles.socialDesc}>
                    <FormattedMessage id="facebook_official_page_caption" defaultMessage="Stay updated on our Official Page" />
                </div>
              </Link>
            )}

            {(!!WL.getFacebook()) && (
                <Link to="/blog" className={styles.socialItem} withLang>
                    <img src={blog} width={36} height={36} alt="blog" className={styles.socialIcon}/>
                    <div className={styles.socialTitle}><FormattedMessage id="blog.blog" defaultMessage="Blog" /></div>
                    <div className={styles.socialDesc}>
                        <FormattedMessage id="blog.blog_caption" defaultMessage="Official Blog" />
                    </div>
                </Link>
            )}
        </div>
    </div>
)

export default Social;
