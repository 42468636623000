import { defineMessages } from 'react-intl';

export const metaMessages = defineMessages({
  defaultTitle: {
    id: 'meta.defaultTitle',
    defaultMessage: "The Ultimate Mining Platform",
  },
  defaultMetaDescription: {
    id: 'meta.defaultMetaDescription',
    defaultMessage: 'Get more from your GPUs and ASICs: batch management, schedules for automatic management, autofan, statistics, and much more'
  },
  defaultMetaOgTitle: {
    id: 'meta.defaultMetaOgTitle',
    defaultMessage: 'The Ultimate Mining Platform'
  },
  installTitle: {
    id: 'meta.installTitle',
    defaultMessage: "Install",
  },
  installMetaDescription: {
    id: 'meta.installMetaDescription',
    defaultMessage: 'Install from 1 to 1000 GPUs or ASICs within a few minutes. Manage your workers from anywhere in the world'
  },
  installMetaOgTitle: {
    id: 'meta.installMetaOgTitle',
    defaultMessage: 'Install'
  },
  featuresTitle: {
    id: 'meta.featuresTitle',
    defaultMessage: "Features",
  },
  featuresMetaDescription: {
    id: 'meta.featuresMetaDescription',
    defaultMessage: 'Learn more about Hive OS features to get more from your mining devices: autofan, RX Boost, workers bulk installation, activity logs, and many more'
  },
  featuresMetaOgTitle: {
    id: 'meta.featuresMetaOgTitle',
    defaultMessage: 'Features'
  },
  pricingTitle: {
    id: 'meta.pricingTitle',
    defaultMessage: "Pricing",
  },
  pricingMetaDescription: {
    id: 'meta.pricingMetaDescription',
    defaultMessage: 'Find a Hive OS package that will meet all your mining needs'
  },
  pricingMetaOgTitle: {
    id: 'meta.pricingMetaOgTitle',
    defaultMessage: 'Pricing'
  },
  contactTitle: {
    id: 'meta.contactTitle',
    defaultMessage: "Contacts",
  },
  contactMetaDescription: {
    id: 'meta.contactMetaDescription',
    defaultMessage: 'Multilingual support team of Hive OS is always here to help you – just contact us!'
  },
  contactMetaOgTitle: {
    id: 'meta.contactMetaOgTitle',
    defaultMessage: 'Contacts'
  },
  changelogTitle: {
    id: 'meta.changelogTitle',
    defaultMessage: "Changelog",
  },
  changelogMetaDescription: {
    id: 'meta.changelogMetaDescription',
    defaultMessage: 'Track all the upgrades of Hive OS'
  },
  changelogMetaOgTitle: {
    id: 'meta.changelogMetaOgTitle',
    defaultMessage: 'Changelog'
  },
  asicTitle: {
    id: 'meta.asicTitle',
    defaultMessage: "Hiveon|ASIC firmware powered by MSKMINER",
  },
  asicMetaDescription: {
    id: 'meta.asicMetaDescription',
    defaultMessage: 'Get higher profits, save electric energy, protect ASICs from viruses and use Hive OS for free'
  },
  asicMetaOgTitle: {
    id: 'meta.asicMetaOgTitle',
    defaultMessage: 'Hiveon|ASIC firmware powered by MSKMINER'
  },
  statisticsTitle: {
    id: 'meta.statisticsTitle',
    defaultMessage: "Statistics",
  },
  statisticsMetaDescription: {
    id: 'meta.statisticsMetaDescription',
    defaultMessage: 'Discover the Hive OS network statistics on coins, algorithms, etc'
  },
  statisticsMetaOgTitle: {
    id: 'meta.statisticsMetaOgTitle',
    defaultMessage: 'Statistics'
  },
  l3Title: {
    id: 'meta.l3Title',
    defaultMessage: "Hiveon|ASIC L3+/L3++ Firmware",
  },
  l3MetaDescription: {
    id: 'meta.l3MetaDescription',
    defaultMessage: 'Get up to 720MH/s with standard PSU, speed up worn ASIC chips, protect your devices from damage, and use Hive OS for free'
  },
  l3MetaOgTitle: {
    id: 'meta.l3MetaOgTitle',
    defaultMessage: 'Hiveon|ASIC L3+/L3++ Firmware'
  },
  s9Title: {
    id: 'meta.s9Title',
    defaultMessage: "Hiveon|ASIC S9/S9j/S9i Firmware",
  },
  s9MetaDescription: {
    id: 'meta.s9MetaDescription',
    defaultMessage: 'Get up to 16.8TH/s with standard PSU, reduce power consumption, speed up worn ASIC chips, and use Hive OS for free'
  },
  s9MetaOgTitle: {
    id: 'meta.s9MetaOgTitle',
    defaultMessage: 'Hiveon|ASIC S9/S9j/S9i Firmware'
  },
  s10Title: {
    id: 'meta.s10Title',
    defaultMessage: "Hiveon|ASIC S10 (S9 Dual) Firmware",
  },
  s10MetaDescription: {
    id: 'meta.s10MetaDescription',
    defaultMessage: 'Get up to 21.5TH/s with standard PSU, protect your devices from viruses, reduce power consumption, and use Hive OS for free'
  },
  s10MetaOgTitle: {
    id: 'meta.s10MetaOgTitle',
    defaultMessage: 'Hiveon|ASIC S10 (S9 Dual) Firmware'
  },
  s17Title: {
    id: 'meta.s17Title',
    defaultMessage: "Hiveon|ASIC S17/S17 Pro/S17+/S17E Firmware",
  },
  s17MetaDescription: {
    id: 'meta.s17MetaDescription',
    defaultMessage: 'Get up to 80.1TH/s with standard PSU, reduce power consumption, use Hive OS for free, and protect your devices from viruses'
  },
  s17MetaOgTitle: {
    id: 'meta.s17MetaOgTitle',
    defaultMessage: 'Hiveon|ASIC S17/S17 Pro/S17+/S17E Firmware'
  },
  t9Title: {
    id: 'meta.t9Title',
    defaultMessage: "Hiveon|ASIC T9+ Firmware",
  },
  t9MetaDescription: {
    id: 'meta.t9MetaDescription',
    defaultMessage: 'Get up to 13.6TH/s with standard PSU, use Hive OS for free, perform actions on schedule, and protect your devices from viruses'
  },
  t9MetaOgTitle: {
    id: 'meta.t9MetaOgTitle',
    defaultMessage: 'Hiveon|ASIC T9+ Firmware'
  },
  t17Title: {
    id: 'meta.t17Title',
    defaultMessage: "Hiveon|ASIC T17/T17+/T17E Firmware",
  },
  t17MetaDescription: {
    id: 'meta.t17MetaDescription',
    defaultMessage: 'Get up to 56TH/s with standard PSU, use Hive OS for free, protect your devices from damage and viruses, and reduce energy consumption'
  },
  t17MetaOgTitle: {
    id: 'meta.t17MetaOgTitle',
    defaultMessage: 'Hiveon|ASIC T17/T17+/T17E Firmware'
  },
  privacyTitle: {
    id: 'meta.privacyTitle',
    defaultMessage: "Hiveon|Privacy Policy",
  },
  privacyMetaDescription: {
    id: 'meta.privacyMetaDescription',
    defaultMessage: 'Learn more about information collection and use in Hive OS'
  },
  privacyMetaOgTitle: {
    id: 'meta.privacyMetaOgTitle',
    defaultMessage: 'Hiveon|Privacy Policy'
  },
  termsTitle: {
    id: 'meta.termsTitle',
    defaultMessage: "Hiveon|Terms of Use",
  },
  termsMetaDescription: {
    id: 'meta.termsMetaDescription',
    defaultMessage: 'Discover all the rules and regulations of Hive OS'
  },
  termsMetaOgTitle: {
    id: 'meta.termsMetaOgTitle',
    defaultMessage: 'Hiveon|Terms of Use'
  },
  knowledgebaseTitle: {
    id: 'meta.knowledgebaseTitle',
    defaultMessage: "Knowledge Base",
  },
  knowledgebaseMetaDescription: {
    id: 'meta.knowledgebaseMetaDescription',
    defaultMessage: 'Guides, manuals and articles about Hive OS, Hiveon ASIC Firmware and Hiveon Pool: find answers to all your questions'
  },
  knowledgebaseMetaOgTitle: {
    id: 'meta.knowledgebaseMetaOgTitle',
    defaultMessage: 'Knowledge Base'
  },
  blogTitle: {
    id: 'meta.blogTitle',
    defaultMessage: "Blog",
  },
  blogMetaDescription: {
    id: 'meta.blogMetaDescription',
    defaultMessage: 'Official Hive OS blog'
  },
  blogMetaOgTitle: {
    id: 'meta.blogMetaOgTitle',
    defaultMessage: 'Blog'
  },
  bugbountyTitle: {
    id: 'meta.bugbountyTitle',
    defaultMessage: "Bug Bounty",
  },
  bugbountyMetaDescription: {
    id: 'meta.bugbountyMetaDescription',
    defaultMessage: 'Bug Bounty Program'
  },
  bugbountyMetaOgTitle: {
    id: 'meta.bugbountyMetaOgTitle',
    defaultMessage: 'Bug Bounty'
  },
  ASICHub: {
    id: 'meta.asicHub',
    defaultMessage: 'ASIC Hub'
  },
  referralTitle: {
    id: 'meta.referralTitle',
    defaultMessage: 'Partnership with Hive',
  },
  referralDescription: {
    id: 'meta.referralDescription',
    defaultMessage: 'Get maximum profit with Hive OS. Receive 10% from payments your miners made',
  },
  asichubMetaDescription: {
    id: 'meta.asichubMetaDescription',
    defaultMessage: 'A time and cost effective solution. Install thousands of ASICs within minutes and use the benefits of Hive OS.',
  },
})
