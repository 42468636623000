import WL from 'constants/whiteLabel';

const { key: wlKey } = WL;

const hiveModels = {
  S17: {
    key: 'S17',
    title: 'S17 / Pro',
    devfee: '2.8%',
    hashrate: '84TH/s',
    fullTitle: 'S17/S17+/S17E, S17 Pro',
    models: [
      {
        title: 'S17/S17 Pro',
        hashrate: '80.1TH/s',
        options: [
          {
            intl: 's17Option2',
            hashrate: '80.1Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.hiveos.farm/asic/temp_s17/Antminer-S17-S17pro-s17p-Hiveon-latest.tar.gz',
          },
        ],
        improved: true,
      },
      {
        title: 'S17/S17 Pro',
        hashrate: '80.1TH/s',
        options: [
          {
            intl: 's17Option2',
            hashrate: '80.1Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.hiveos.farm/asic/temp_s17/SD-S17-S17pro-Hiveon-latest.zip',
          },
        ],
        improved: true,
        sdCard: true,
      },
      {
        title: 'S17+',
        hashrate: '80.1TH/s',
        options: [
          {
            intl: 's17Option2',
            hashrate: '95Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.hiveos.farm/asic/temp_s17/Antminer-S17Plus-Hiveon-latest.tar.gz',
          },
        ],
        improved: true,
      },
      {
        title: 'S17+',
        hashrate: '80.1TH/s',
        options: [
          {
            intl: 's17Option2',
            hashrate: '95Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.hiveos.farm/asic/temp_s17/SD-S17%2B-Hiveon-latest.zip',
          },
        ],
        improved: true,
        sdCard: true,
      },
      {
        title: 'S17E',
        hashrate: '80.1TH/s',
        options: [
          {
            intl: 's17Option2',
            hashrate: '77.5Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.hiveos.farm/asic/temp_s17/Antminer-S17E-Hiveon-latest.tar.gz',
          },
        ],
        improved: true,
      },
      {
        title: 'S17E',
        hashrate: '80.1TH/s',
        options: [
          {
            intl: 's17Option2',
            hashrate: '77.5Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.hiveos.farm/asic/temp_s17/SD-S17E-Hiveon-latest.zip',
          },
        ],
        improved: true,
        sdCard: true,
      },
    ],
  },
  T17: {
    key: 'T17',
    title: 'T17',
    devfee: '2.8%',
    hashrate: '59TH/s',
    fullTitle: 'T17/T17+/T17E',
    models: [
      {
        title: 'T17',
        hashrate: '56TH/s',
        options: [
          {
            intl: 't17Option1',
            hashrate: '56/59Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.hiveos.farm/asic/temp_t17/Antminer-T17-Hiveon-latest.tar.gz',
          },
        ],
        improved: true,
      },
      {
        title: 'T17',
        hashrate: '56TH/s',
        options: [
          {
            intl: 't17Option1',
            hashrate: '56/59Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.hiveos.farm/asic/temp_t17/SD-T17-Hiveon-latest.zip',
          },
        ],
        improved: true,
        sdCard: true,
      },
      {
        title: 'T17+',
        hashrate: '56TH/s',
        options: [
          {
            intl: 't17Option1',
            hashrate: '70/75Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.hiveos.farm/asic/temp_t17/Antminer-T17Plus-Hiveon-latest.tar.gz',
          },
        ],
        improved: true,
      },
      {
        title: 'T17+',
        hashrate: '56TH/s',
        options: [
          {
            intl: 't17Option1',
            hashrate: '70/75Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.hiveos.farm/asic/temp_t17/SD-T17%2B-Hiveon-latest.zip',
          },
        ],
        improved: true,
        sdCard: true,
      },
      {
        title: 'T17E',
        hashrate: '56TH/s',
        options: [
          {
            intl: 't17Option1',
            hashrate: '62.7/67.2Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.hiveos.farm/asic/temp_t17/Antminer-T17E-Hiveon-latest.tar.gz',
          },
        ],
        improved: true,
      },
      {
        title: 'T17E',
        hashrate: '56TH/s',
        options: [
          {
            intl: 't17Option1',
            hashrate: '62.7/67.2Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.hiveos.farm/asic/temp_t17/SD-T17E-Hiveon-latest.zip',
          },
        ],
        improved: true,
        sdCard: true,
      },
    ],
  },
  L3: {
    key: 'L3',
    title: 'L3',
    devfee: '1.8%',
    hashrate: '720MH/s',
    fullTitle: 'L3+/L3++',
    models: [
      {
        title: 'L3+/L3++',
        hashrate: '720MH/s',
        options: [
          {
            intl: 'l3Option1',
            hashrate: '720 MH/s',
          },
        ],
        buttons: [
          {
            title: 'Standard',
            titleIntl: 'downloadButton',
            href: 'https://download.hiveos.farm/asic/L3+/Antminer-L3Plus-Hiveon-latest.tar.gz'
          },
        ],
        improved: true,
      },
      {
        title: 'L3+/L3++',
        hashrate: '720MH/s',
        options: [
          {
            intl: 'l3Option1',
            hashrate: '720 MH/s',
          },
        ],
        buttons: [
          {
            title: 'Standard',
            titleIntl: 'downloadButton',
            href: 'https://download.hiveos.farm/asic/L3%2B/SD-L3%2B-Hiveon-latest.zip'
          },
        ],
        improved: true,
        sdCard: true,
      },
    ],
  },
  S10: {
    key: 'S10',
    title: 'S10',
    devfee: '2%',
    hashrate: '23.5TH/s',
    fullTitle: 'S10 (S9 Dual)',
    models: [
      {
        title: 'S10',
        hashrate: '21.5TH/s',
        options: [
          {
            intl: 's10Option1',
            hashrate: '23.5Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.hiveos.farm/asic/S10/Antminer-S10-all-Hiveon.tar.gz',
          },
        ],
        improved: true,
      },
      {
        title: 'S10',
        hashrate: '21.5TH/s',
        options: [
          {
            intl: 's10Option1',
            hashrate: '23.5Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.hiveos.farm/asic/S10/SD-S10-Hiveon-latest.zip',
          },
        ],
        improved: true,
        sdCard: true,
      },
    ],
  },
  S9: {
    key: 'S9',
    title: 'S9',
    devfee: '2%',
    hashrate: '19.1TH/s',
    fullTitle: 'S9/S9j/S9i',
    models: [
      {
        title: 'S9',
        hashrate: '16.8TH/s',
        options: [
          {
            intl: 's9Option1',
            hashrate: '19.1Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.hiveos.farm/asic/s9/Antminer-S9-all-Hiveon.tar.gz',
          },
        ],
        improved: true,
      },
      {
        title: 'S9',
        hashrate: '16.8TH/s',
        options: [
          {
            intl: 's9Option1',
            hashrate: '19.1Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.hiveos.farm/asic/s9/SD-S9-Hiveon-latest.zip',
          },
        ],
        improved: true,
        sdCard: true,
      },
      {
        title: 'S9j',
        hashrate: '16.8TH/s',
        options: [
          {
            intl: 's9Option1',
            hashrate: '19.1Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.hiveos.farm/asic/s9/Antminer-S9-all-Hiveon.tar.gz',
          },
        ],
        improved: true,
      },
      {
        title: 'S9j',
        hashrate: '16.8TH/s',
        options: [
          {
            intl: 's9Option1',
            hashrate: '19.1Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.hiveos.farm/asic/s9/SD-S9-Hiveon-latest.zip',
          },
        ],
        improved: true,
        sdCard: true,
      },
      {
        title: 'S9i',
        hashrate: '16.8TH/s',
        options: [
          {
            intl: 's9Option1',
            hashrate: '19.1Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.hiveos.farm/asic/s9/Antminer-S9-all-Hiveon.tar.gz',
          },
        ],
        improved: true,
      },
      {
        title: 'S9i',
        hashrate: '16.8TH/s',
        options: [
          {
            intl: 's9Option1',
            hashrate: '19.1Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.hiveos.farm/asic/s9/SD-S9-Hiveon-latest.zip',
          },
        ],
        improved: true,
        sdCard: true,
      },
    ],
  },
  T9: {
    key: 'T9',
    title: 'T9+',
    devfee: '2%',
    hashrate: '16.1TH/s',
    fullTitle: 'T9+',
    models: [
      {
        title: 'T9+',
        hashrate: '13.6TH/s',
        options: [
          {
            intl: 't9Option1',
            hashrate: '16.1Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standard',
            titleIntl: 'downloadButton',
            href: 'https://download.hiveos.farm/asic/t9/Antminer-T9-all-Hiveon.tar.gz'
          }
        ],
      },
      {
        title: 'T9+',
        hashrate: '13.6TH/s',
        options: [
          {
            intl: 't9Option1',
            hashrate: '16.1Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standard',
            titleIntl: 'downloadButton',
            href: 'https://download.hiveos.farm/asic/t9/SD-T9-Hiveon-latest.zip'
          }
        ],
        sdCard: true,
      },
    ],
  },
};

const bigminerModels = {
  S17: {
    key: 'S17',
    title: 'S17 / Pro',
    devfee: '2.8%',
    hashrate: '84TH/s',
    fullTitle: 'S17/S17+/S17E, S17 Pro',
    models: [
      {
        title: 'S17/S17 Pro',
        hashrate: '80.1TH/s',
        options: [
          {
            intl: 's17Option1',
            hashrate: '80.1Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.bigminer.io/asic/custom/f2pool/201222/Antminer-S17-S17pro-v1.2.0h_CRC_SIG-sig-2.03%40201222-S17-sign-f2pool.tar.gz',
          },
        ],
        improved: true,
      },
      {
        title: 'S17/S17 Pro',
        hashrate: '80.1TH/s',
        options: [
          {
            intl: 's17Option1',
            hashrate: '80.1Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.bigminer.io/asic/custom/f2pool/201222/SD-Antminer-S17-S17pro-v1.2.0h_CRC_SIG-sig-2.03%40201222-S17-sign-f2pool.zip',
          },
        ],
        improved: true,
        sdCard: true,
      },
      // {
      //   title: 'S17+',
      //   hashrate: '80.1TH/s',
      //   options: [
      //     {
      //       intl: 's17Option2',
      //       hashrate: '19.1Th/s',
      //     },
      //   ],
      //   buttons: [
      //     {
      //       title: 'Standart',
      //       titleIntl: 'downloadButton',
      //       href: 'https://download.bigminer.io/asic/custom/f2pool/201222/asic/temp_s17/Antminer-S17Plus-Bigminer-latest.tar.gz',
      //     },
      //   ],
      //   improved: true,
      // },
      // {
      //   title: 'S17+',
      //   hashrate: '80.1TH/s',
      //   options: [
      //     {
      //       intl: 's17Option2',
      //       hashrate: '19.1Th/s',
      //     },
      //   ],
      //   buttons: [
      //     {
      //       title: 'Standart',
      //       titleIntl: 'downloadButton',
      //       href: 'https://download.bigminer.io/asic/custom/f2pool/201222/asic/temp_s17/SD-S17%2B-Bigminer-latest.zip',
      //     },
      //   ],
      //   improved: true,
      //   sdCard: true,
      // },
      {
        title: 'S17E',
        hashrate: '80.1TH/s',
        options: [
          {
            intl: 's17Option3',
            hashrate: '77.5Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.bigminer.io/asic/custom/f2pool/201222/Antminer_S17E-v1.2.0j_CRC_SIG-sig-2.03%40201222-S17E-sign-f2pool.tar.gz',
          },
        ],
        improved: true,
      },
      {
        title: 'S17E',
        hashrate: '80.1TH/s',
        options: [
          {
            intl: 's17Option3',
            hashrate: '77.5Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.bigminer.io/asic/custom/f2pool/201222/SD-Antminer_S17E-v1.2.0j_CRC_SIG-sig-2.03%40201222-S17E-sign-f2pool.zip',
          },
        ],
        improved: true,
        sdCard: true,
      },
    ],
  },
  T17: {
    key: 'T17',
    title: 'T17',
    devfee: '2.8%',
    hashrate: '59TH/s',
    fullTitle: 'T17/T17+/T17E',
    models: [
      {
        title: 'T17',
        hashrate: '56TH/s',
        options: [
          {
            intl: 't17Option1',
            hashrate: '56/59Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.bigminer.io/asic/custom/f2pool/201222/Antminer-T17-v1.2.0h_CRC_SIG-sig-2.03%40201222-T17-sign-f2pool.tar.gz',
          },
        ],
        improved: true,
      },
      {
        title: 'T17',
        hashrate: '56TH/s',
        options: [
          {
            intl: 't17Option1',
            hashrate: '56/59Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.bigminer.io/asic/custom/f2pool/201222/SD-Antminer-T17-v1.2.0h_CRC_SIG-sig-2.03%40201222-T17-sign-f2pool.zip',
          },
        ],
        improved: true,
        sdCard: true,
      },
      {
        title: 'T17E',
        hashrate: '56TH/s',
        options: [
          {
            intl: 't17Option1',
            hashrate: '62.7/67.2Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.bigminer.io/asic/custom/f2pool/201222/Antminer_T17E-v1.2.0j_CRC_SIG-sig-2.03%40201222-T17E-sign-f2pool.tar.gz',
          },
        ],
        improved: true,
      },
      {
        title: 'T17E',
        hashrate: '56TH/s',
        options: [
          {
            intl: 't17Option1',
            hashrate: '62.7/67.2Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.bigminer.io/asic/custom/f2pool/201222/SD-Antminer_T17E-v1.2.0j_CRC_SIG-sig-2.03%40201222-T17E-sign-f2pool.zip',
          },
        ],
        improved: true,
        sdCard: true,
      },
    ],
  },
  // L3: {
  //   key: 'L3',
  //   title: 'L3',
  //   devfee: '1.8%',
  //   hashrate: '720MH/s',
  //   fullTitle: 'L3+/L3++',
  //   models: [
  //     {
  //       title: 'L3+',
  //       hashrate: '720MH/s',
  //       options: [
  //         {
  //           intl: 'l3Option1',
  //           hashrate: '720 MH/s',
  //         },
  //       ],
  //       buttons: [
  //         {
  //           title: 'Standard',
  //           titleIntl: 'downloadButton',
  //           href: 'https://download.bigminer.io/asic/custom/f2pool/201222/asic/L3+/Antminer-L3Plus-Bigminer-latest.tar.gz'
  //         },
  //       ],
  //     },
  //     {
  //       title: 'L3++',
  //       hashrate: '720MH/s',
  //       options: [
  //         'l3Option2',
  //       ],
  //       buttons: [
  //         {
  //           title: 'Standard',
  //           titleIntl: 'downloadButton',
  //           href: 'https://download.bigminer.io/asic/custom/f2pool/201222/asic/L3+/Antminer-L3Plus-Bigminer-latest.tar.gz'
  //         },
  //       ],
  //     },
  //   ],
  // },
  S9: {
    key: 'S9',
    title: 'S9',
    devfee: '2%',
    hashrate: '19.1TH/s',
    fullTitle: 'S9/S9j/S9i',
    models: [
      {
        title: 'S9',
        hashrate: '16.8TH/s',
        options: [
          {
            intl: 's9Option1',
            hashrate: '19.1TH/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.bigminer.io/asic/custom/f2pool/201222/Antminer-S9-all-Bigminer-1.03%40201222-sign-f2pool.tar.gz',
          },
        ],
        improved: true,
      },
      {
        title: 'S9',
        hashrate: '16.8TH/s',
        options: [
          {
            intl: 's9Option1',
            hashrate: '19.1TH/ss',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.bigminer.io/asic/custom/f2pool/201222/SD-Antminer-S9-all-Bigminer-1.03%40201222-sign-f2pool.zip',
          },
        ],
        improved: true,
        sdCard: true,
      },
      {
        title: 'S9j',
        hashrate: '16.8TH/s',
        options: [
          {
            intl: 's9Option2',
            hashrate: '19.1Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.bigminer.io/asic/custom/f2pool/201222/Antminer-S9-all-Bigminer-1.03%40201222-sign-f2pool.tar.gz',
          },
        ],
        improved: true,
      },
      {
        title: 'S9j',
        hashrate: '16.8TH/s',
        options: [
          {
            intl: 's9Option2',
            hashrate: '19.1Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.bigminer.io/asic/custom/f2pool/201222/SD-Antminer-S9-all-Bigminer-1.03%40201222-sign-f2pool.zip',
          },
        ],
        improved: true,
        sdCard: true,
      },
      {
        title: 'S9i',
        hashrate: '16.8TH/s',
        options: [
          {
            intl: 's9Option1',
            hashrate: '19.1TH/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.bigminer.io/asic/custom/f2pool/201222/Antminer-S9-all-Bigminer-1.03%40201222-sign-f2pool.tar.gz',
          },
        ],
        improved: true,
      },
      {
        title: 'S9i',
        hashrate: '16.8TH/s',
        options: [
          {
            intl: 's9Option1',
            hashrate: '19.1TH/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.bigminer.io/asic/custom/f2pool/201222/SD-Antminer-S9-all-Bigminer-1.03%40201222-sign-f2pool.zip',
          },
        ],
        improved: true,
        sdCard: true,
      },
    ],
  },
};

const modelsMap = {
  'hiveos': hiveModels,
  'bigminer': bigminerModels,
}

export default modelsMap[wlKey] || hiveModels;
