import React, { Component } from 'react';
import cx from 'classnames';
import _get from 'lodash/get';
import { FormattedMessage } from 'react-intl';
import WL from 'constants/whiteLabel';
import Link from 'components/Link';
import LanguageSwitcher from 'components/LanguageSwitcher';
import withLocation from 'helpers/withLocation';
import styles from './headerSections.module.css';

class HeaderSection extends Component {
  renderNavItem(item) {
    return (
      <React.Fragment>
        <div className={styles.main}>
          <span>{_get(item, 'title.main')}</span>
          {
            _get(item, 'title.sub') ? (
              <React.Fragment>
                {' '}
                <span className={styles.subMain}>{_get(item, 'title.sub') }</span>
              </React.Fragment>
            ) : null
          }
        </div>
        <div className={styles.underText}>
          <FormattedMessage id={_get(item, 'subtitle') } />
        </div>
      </React.Fragment>
    )
  }

  render() {
    const {
      location,
    } = this.props;
    const isAsic = this.props.isAsic || false;
    const currentPath = location.pathname;

    return (
      <div className={styles.root}>
        <div className={styles.sections}>
          {
            WL.topNav && WL.topNav.map(item => {
              if (_get(item, 'isExternal')) {
                return (
                  <Link external noRef className={styles.item} href={_get(item, 'link')} key={_get(item, 'link')} target="_self">
                    {this.renderNavItem(item)}
                  </Link>
                );
              }

              return (
                <Link withLang className={cx(styles.item, {
                  [styles.active]: _get(item, 'link') === '/' ? ['', '/', 'ru', '/ru/'].includes(currentPath)
                    : _get(item, 'link') === '/asic'
                      ? isAsic
                      : (_get(item, 'link', '').replace(/\//g, '') === (currentPath ? currentPath.replace(/\//g, '').replace('_ru', '') : null))
                  })} to={_get(item, 'link')} key={_get(item, 'link')}>
                  {this.renderNavItem(item)}
                </Link>
              )
            })
          }
        </div>
        <div>
          <LanguageSwitcher />
        </div>
      </div>
    );
  }
}

export default withLocation(HeaderSection);
